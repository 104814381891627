import { createContext, FunctionComponent, useContext, useEffect } from 'react'
import Store from './store'

const StoreContext = createContext({} as Store)

export const MainStoreProvider: FunctionComponent<{}> = ({children}) => {

  const store = new Store()
  useEffect(() => {
    store.init()
  }, [ store ])

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  )
}

export const useMainStore = () => {
  const store = useContext(StoreContext)
  return store
}