import axios from "axios";
import MainStore from "./store";

class API {

  mainStore: MainStore
  
  constructor(mainStore: MainStore){
    this.mainStore = mainStore
  }

  private getHeaders() {
    const headers: any = {}
    if (this.mainStore.deviceId)
      headers['device-id'] = this.mainStore.deviceId
    return headers
  }

  async getQrData(code_uuid: string){
    try { 
      const resp = await axios.get("/api/codes/"+code_uuid, { headers: this.getHeaders() })
      const data = resp.data
      return data

    } catch (e: any) {
      if (e.data) return e.data
      return { error: "Request error" }
    }
  }

  async sendFormData(url: string, formData: FormData, cb: (percent: number) => void){
    const resp = await axios.post(url, formData, { 
      headers: this.getHeaders(),
      onUploadProgress: e => cb(e)
    })
    return resp.data
  }

  async sendDesignData (code_uuid: string, design: QrData['design']) {
    const resp = await axios.post("/api/codes/"+code_uuid+"/design", { design }, {
      headers: this.getHeaders()
    })
    return resp.data
  }

  async validateEditLink (code_uuid: string, edit_uuid: string) {
    if (!edit_uuid || !code_uuid)
      return false

    try {
      const valid = await axios.get("/api/codes/"+code_uuid+"/validate/"+edit_uuid)
      if (valid.data.isValid === true) return true
      return false
    } catch(e) {
      return false
    }
  }

}

export default API