import { makeAutoObservable } from "mobx"

class MessageStore {

  message: string | null = null
  showMessage: boolean = false
  timeout: number = -1

  constructor() {
    makeAutoObservable(this, {
      timeout: false,
      alert: false
    })
  }

  private setMessage(text: string | null){
    if (text !== null){
      this.message = text
      this.showMessage = true
    } else {
      this.showMessage = false
    }
    if (this.timeout > 0){
      clearTimeout(this.timeout)
      this.timeout = -1
    }
  }

  alert(text: string){
    this.setMessage(text)
    this.timeout = setTimeout((() => this.setMessage(null)) as TimerHandler, 3000)
  }

}

export default MessageStore