import { FunctionComponent } from "react";
import styles from "./style.module.sass"
import { ReactComponent as Icon } from './rotate-icon.svg'
import { useMainStore } from "main-store";

const RotateTool: FunctionComponent<{}> = () => {

  const mainStore = useMainStore()

  const rotate = () => {
    const angle = mainStore.currentCode?.design?.rotate || 0
    let newAngle = angle-90
    if (newAngle < 0)
      newAngle += 360
    mainStore.setRotate(newAngle)
  }

  return (
    <button className={styles.rotateTool} onClick={rotate}>
      <Icon />
    </button>
  )

}

export default RotateTool;