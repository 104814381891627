import { FunctionComponent } from 'react'
import { useMainStore } from 'main-store'
import { observer } from 'mobx-react-lite'

import Scanner from "scanner"
import CreateProjectPage from './create-project'
import EditPage from './edit'
import FinalPage from './final'

type AppProps = {
  title: string
}

function App ({ title }: AppProps) {

  const mainStore = useMainStore()

  if (mainStore.page === "") return <Scanner/>
  if (mainStore.page === "createProject") return <CreateProjectPage uuid={mainStore.currentCode?.uuid || ""}/>
  if (mainStore.page === "preview") return <Scanner preview={true}/>
  if (mainStore.page === "edit") return <EditPage/>
  if (mainStore.page === "final") return <FinalPage/>

  return <div></div>
}


export default observer(App) as FunctionComponent<AppProps>;
