import cn from "classnames";
import { useMainStore } from "main-store";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import styles from './style.module.sass'

type Props = {}

const MessageLayout: FunctionComponent<Props> = observer(({ children }) => {

  const mainStore = useMainStore()

  return (
    <>
      { children }
      { 
        <div className={cn(styles.message, !mainStore.messageStore.showMessage && styles.hide)}>
          { mainStore.messageStore.message }
        </div> 
      }
    </>
  )
})

export default MessageLayout