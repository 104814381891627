import { lerp } from 'libs/math'
import { waitCameraData } from 'scanner/libs/camera'
import { decompose, getCameraMatrix } from 'scanner/process-image/decompose'
import * as THREE from 'three'
import { Object3D } from 'three';
const { Interaction } = require('../../../node_modules/three.interaction/src/index');
console.log(Interaction)
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

THREE.Cache.enabled = true
const rotationMatrix = new THREE.Matrix4()
rotationMatrix.makeRotationFromEuler(new THREE.Euler(
	-THREE.MathUtils.degToRad(90),
	0,
	0,
	'XYZ'
))

const cvToGlMatrix = new THREE.Matrix4()
cvToGlMatrix.set(
	1, 0, 0, 0,
	0, -1, 0, 0,
	0, 0, -1, 0,
	0, 0, 0, 1
)

const preScale = new THREE.Matrix4()
preScale.set(
	1, 0, 0, 0,
	0, -1, 0, 0,
	0, 0, 1, 0,
	0, 0, 0, 1
)

const pattern2dTranslateMatrix = new THREE.Matrix4().setPosition(0, 0, 0.01)
const scaleMatrix = new THREE.Matrix4().setPosition(0, 0, 0)

export default class GL {

	renderer: THREE.WebGLRenderer
	scene2D: THREE.Scene
	scene3D: THREE.Scene
	camera3D: THREE.PerspectiveCamera
	camera2D: THREE.OrthographicCamera
	scaleMatrix: THREE.Matrix4
	preScaleMatrix: THREE.Matrix4
	scale = 1
	mediaRotation = 0
	mixer: THREE.AnimationMixer | null = null
	clock = new THREE.Clock()
	cameraMatrix: number[][]
	mediaPlane: THREE.Object3D | null = null
	
	layers2d: (THREE.Object3D | null)[] = []
	layers3d: (THREE.Object3D | null)[] = []

	cameraFovAngle = 70

	constructor(canvas: HTMLCanvasElement){
		this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
		this.renderer.autoClear = false

		this.scene2D = new THREE.Scene()
		this.scene3D = new THREE.Scene()
		this.camera3D = new THREE.PerspectiveCamera( this.cameraFovAngle, canvas.width / canvas.height, 0.1, 1000 )
		this.camera2D = new THREE.OrthographicCamera(-canvas.width/2, canvas.width/2, canvas.height/2, -canvas.height/2, 0.1, 1000)
		this.camera2D.position.z = 5;

		const light = new THREE.AmbientLight( "#E3B1B0", 1.5);
		const directionalLight = new THREE.DirectionalLight( 0xffffff, 2 );
		directionalLight.position.set(0.5, 1, 0.7)
		//this.directionalLight.castShadow = true
		this.scene3D.add (light, directionalLight)
		
		const light2D = new THREE.AmbientLight( "#FFFFFF", 2);
		this.scene2D.add (light2D) 

		this.scaleMatrix = new THREE.Matrix4()
		this.scaleMatrix.set(
			1, 0, 0, -canvas.width/2,
			0, -1, 0, canvas.height/2,
			0, 0, 1, 0,
			0, 0, 0, 1
		)

		this.preScaleMatrix = new THREE.Matrix4()
		this.preScaleMatrix.set(
			1, 0, 0, 0,
			0, -1, 0, 0,
			0, 0, 1, 0,
			0, 0, 0, 1
		)

		this.cameraMatrix = getCameraMatrix(canvas.height, canvas.width, this.cameraFovAngle)

		//this.importModel('/models/priora.glb')

		// new a interaction, then you can add interaction-event with your free style
		const interaction = new Interaction(this.renderer, this.scene3D, this.camera3D);
	}

	videoTexture: THREE.Texture | null = null

	setVideoBackground(video: HTMLCanvasElement){
		this.videoTexture = new THREE.Texture( video )
		this.videoTexture.minFilter = THREE.LinearFilter
		this.videoTexture.generateMipmaps = false
		this.scene2D.background = this.videoTexture
	}

	updateBackground () {
		if (this.videoTexture === null) return
		this.videoTexture.needsUpdate = true
	}

	textureLoader = new THREE.TextureLoader()
	refVideoElement: HTMLVideoElement | null = null
	importedSrc = ""
	showMedia = false
	
	async importMedia(data: QrMediaSrc, scale: number = 1) {
		if (data.videoSrc === this.importedSrc || data.imageSrc === this.importedSrc){
			this.showMedia = true
			return
		}
		if (this.layers2d[0]) {
			this.layers2d[0].removeFromParent()
			this.layers2d[0] = null
			this.mediaPlane = null
		}
		
		const gltf = new THREE.Scene()
		const material = new THREE.MeshBasicMaterial()
		const size = { width: 1, height: 1 }

		if (data.imageSrc) {
			const texture = await this.textureLoader.loadAsync(data.imageSrc)
			material.map = texture
			size.width = texture.image.width
			size.height = texture.image.height
			this.importedSrc = data.imageSrc

			if (data.imageSrc.toLowerCase().endsWith(".png"))
				material.transparent = true;
		} 

		if (data.videoSrc) {
			if(!this.refVideoElement) return
			this.refVideoElement.src = data.videoSrc
			this.refVideoElement.poster = data.preview || ""
			const texture = new THREE.VideoTexture( this.refVideoElement );
			material.map = texture
			await waitCameraData(this.refVideoElement, 1)
			size.width = this.refVideoElement.videoWidth
			size.height = this.refVideoElement.videoHeight
			this.importedSrc = data.videoSrc
		}

		const a = 1/Math.max(size.width, size.height) * (scale)
		const geometry = new THREE.PlaneGeometry( size.width * a, size.height * a );
		this.mediaPlane = new THREE.Mesh( geometry, material );
		//plane.setRotationFromEuler(new THREE.Euler(-90, 0, 0))

		this.mediaPlane.setRotationFromEuler(new THREE.Euler(0, 0, this.mediaRotation * Math.PI / 180))
		gltf.add(this.mediaPlane)
		this.scene2D.add(gltf)
		this.layers2d[0] = gltf
		this.showMedia = true
	}

	videoPlayButton: Object3D | null = null
	async createVideoPlayButton() {
		const texture = await this.textureLoader.loadAsync("/video-play.png")
		const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
		const geometry = new THREE.PlaneGeometry( 0.5, 0.5 );
		const plane = new THREE.Mesh( geometry, material );

		this.videoPlayButton = plane
		this.scene2D.add(plane)
		this.layers2d[1] = this.videoPlayButton
	}

	setVideoPlayButton(value: boolean) {
		if (value && !this.videoPlayButton) {
			this.createVideoPlayButton()
		}
		this.layers2d[1] = value? this.videoPlayButton: null
		if (this.videoPlayButton) {
			this.videoPlayButton.visible = value
		}
	}

	setPatternScene(pattern: Pattern){
		if (pattern.scene2d) {
			this.layers2d[10] = pattern.scene2d
			this.scene2D.add(this.layers2d[10])
		}
		
		if (pattern.scene3d) {
			this.layers3d[10] = pattern.scene3d
			this.scene3D.add(this.layers3d[10])
		}
		
		if (pattern.mixer){
			this.mixer = pattern.mixer 
			this.clock.start()
		}else {
			if (this.mixer) {
				this.mixer.stopAllAction()
				this.mixer = null
			}
		}
	}

	removePatternScene() {
		if (this.layers2d[10]) this.layers2d[10].removeFromParent()
		if (this.layers3d[10]) this.layers3d[10].removeFromParent()

		this.layers2d[10] = null
		this.layers3d[10] = null
	}

	setGlobalScale(scale: number) {
		this.scale = scale
	}

	rotateFlag = false
	setMediaRotation(angle: number) {

		if (this.mediaRotation === angle) return
		let startAngle = this.mediaRotation;
		if (startAngle < angle - 180) startAngle += 360
		this.mediaRotation = angle
		if (!this.mediaPlane) return
		
		this.rotateFlag = true
		const startTime = performance.now();
		const rotate = (timestep: number) => {
			const time = (timestep-startTime) / 1000 / 0.2;
			
			if (!this.mediaPlane || time >= 1) {
				this.rotateFlag = false;
				this.mediaPlane?.setRotationFromEuler(new THREE.Euler(0, 0, angle * Math.PI / 180))
				return
			}

			this.mediaPlane?.setRotationFromEuler(new THREE.Euler(0, 0, lerp(startAngle* Math.PI / 180, angle * Math.PI / 180, time)))
			requestAnimationFrame(rotate)
		}
		rotate(performance.now())
	}

	// render(matrix: number[][] | null){
	// 	if(this.gltf){
	// 		if(matrix){
	// 			this.gltf.matrixAutoUpdate = false
	// 			this.gltf.visible = true
	// 			this.gltf.matrix.set(
	// 				matrix[0][0], matrix[0][1], matrix[0][2], matrix[0][3], 
	// 				matrix[1][0], matrix[1][1],	matrix[1][2], matrix[1][3], 
	// 				matrix[2][0], matrix[2][1], matrix[2][2], matrix[2][3], 
	// 			0, 0, 0, 1).premultiply(cvToGlMatrix).multiply(rotationMatrix)
	// 		}else{
	// 			this.gltf.visible = false
	// 		}
	// 	}
	// 	this.renderer.render(this.scene, this.camera);
	// }

	render2D(homography: number[][] | null){

		this.renderer.clear()

		// Рендер 2D элементов
		let i = 0
		for(let layer of this.layers2d){
			if (!layer) continue
			if (!homography || !this.showMedia) {
				layer.visible = false
				continue
			}

			layer.matrixAutoUpdate = false
			layer.visible = true
			layer.renderOrder = 20+i

			scaleMatrix.makeScale(layer.scale.x * this.scale, layer.scale.y * this.scale, layer.scale.z * this.scale)
			pattern2dTranslateMatrix.setPosition(0, 0, i)

			layer.matrix.set(
				homography[0][0],	homography[0][1], 0,	homography[0][2], 
				homography[1][0],	homography[1][1], 0,	homography[1][2], 
				0, 								0, 								1,	0,
				homography[2][0],	homography[2][1], 0,	homography[2][2]
			)
			.premultiply(this.scaleMatrix)
			.multiply(preScale)
			.multiply(scaleMatrix)
			.premultiply(pattern2dTranslateMatrix)

			i += 0.01

			if (this.mixer)
				this.mixer.update(this.clock.getDelta())
		}

		this.renderer.render(this.scene2D, this.camera2D)

		// Рендер 3D элементов
		const matrix = decompose(homography, this.cameraMatrix)

		i = 0
		for(let layer of this.layers3d){
			if (!layer) continue
			if (!matrix || !this.showMedia) {
				layer.visible = false
				continue
			}

			scaleMatrix.makeScale(layer.scale.x * this.scale, layer.scale.y * this.scale, layer.scale.z * this.scale)
			layer.matrixAutoUpdate = false
			layer.visible = true
			layer.renderOrder = 50

			layer.matrix.set(
				matrix[0][0], matrix[0][1], matrix[0][2], matrix[0][3], 
				matrix[1][0], matrix[1][1],	matrix[1][2], matrix[1][3], 
				matrix[2][0], matrix[2][1], matrix[2][2], matrix[2][3], 
				0, 0, 0, 1).premultiply(cvToGlMatrix).multiply(rotationMatrix).multiply(scaleMatrix)
		}
		this.renderer.clearDepth()
		
		this.renderer.render(this.scene3D, this.camera3D)
	}
}