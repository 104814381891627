import { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useMainStore } from 'main-store'
import Footer from 'components/footer'

type EditPageProps = {

}


function EditPage () {

  const mainStore = useMainStore()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const codeUuid = window.location.pathname.slice(1)
    const editUuid = params.get("edit")
    mainStore.api.validateEditLink(codeUuid, editUuid || "").then((isValid) => {
      if (!isValid){
        mainStore.openPage("")
        mainStore.messageStore.alert("Ссылка не действительна")
      }
    })
  }, [ mainStore ])
  
  const changeMedia = () => {
    const codeUuid = window.location.pathname.slice(1)
    mainStore.initCode(codeUuid)
    mainStore.openPage("createProject")
  }
  
  const changePattern = () => {
    const codeUuid = window.location.pathname.slice(1)
    mainStore.initCode(codeUuid)
    mainStore.openPage("")
  }

  return (
    <div className="page">
      <div className="page-content">
        <h1>Изменение проекта</h1>
        
        <div>Чтобы бы вы хотели изменить?</div>
        <button style={{margin: "1em"}} className="btn" onClick={changeMedia}>Изменить фото/видео</button>
        <div style={{margin: "0.4em"}}>Или</div>
        <button style={{margin: "1em"}} className="btn" onClick={changePattern}>Выбрать другой шаблон</button>

      </div>
      <Footer/>
    </div>
  )
}

export default observer(EditPage) as FunctionComponent<EditPageProps>;
