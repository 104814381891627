import { makeAutoObservable } from "mobx"
import MainStore from "./store"

class FileStore {

  mainStore: MainStore

  status: '' | 'loading' | 'compressing' | 'success' | 'error' = ''
  loadedProgress = ""

  constructor(mainStore: MainStore) {
    makeAutoObservable(this)
    this.mainStore = mainStore
  }

  setUploadProgress(e: any) {
    this.loadedProgress = 
      (Math.floor(e.loaded/1024 / 1024 * 10)/10)+"/"+
      (Math.floor(e.total/1024 / 1024 * 10)/10)+"мб"
    console.log(e)
  }

  setProgress(progress: string) {
    this.loadedProgress = progress
  }

  setStatus(status: typeof this.status){
    this.status = status
  }

  async updateCompressingInfo() {
    if (!this.mainStore.currentCode) return
    const qrData: QrData = await this.mainStore.api.getQrData(this.mainStore.currentCode.uuid) 
    if (!qrData.src) return

    console.log(qrData)

    if (qrData.src.isConverted) {
      this.complete()
      this.setProgress("Успешно!")
      return
    }
    if (qrData.src.progress) {
      this.setProgress(Math.floor(qrData.src.progress) + "%")
    }
    setTimeout(this.updateCompressingInfo.bind(this), 800)
  }

  complete() {
    this.setStatus("success")
    this.mainStore.openPage('')
  }

  async uploadFile(file: File) {
    if (!this.mainStore.currentCode) return 
    
    const formData = new FormData()
    formData.append("file", file)
    
    this.setStatus('loading')
    const resp = await this.mainStore.api.sendFormData(
      `/api/codes/${this.mainStore.currentCode.uuid}/upload`, 
      formData, 
      this.setUploadProgress.bind(this)
    )
    
    if (resp.error) return this.setStatus('error')
    if (resp.type === "video") {
      this.setStatus('compressing')
      this.updateCompressingInfo()
    } else {
      this.complete()
    }
  }

}

export default FileStore