function getPos(_e: any) {
  const e = ((_e.touches && _e.touches[0]) || _e) as MouseEvent
  return { x: e.clientX, y: e.clientY }
}

type HandleCallbackProps = {
  pos: { x: number, y: number },
  deltaPos: { x: number, y: number },
  startPos: { x: number, y: number }
}

type HandleEventOpts = {
  onStart?: (props: HandleCallbackProps) => void
  onMove?: (props: HandleCallbackProps) => void,
  onEnd?: (props: HandleCallbackProps) => void
}

export function getMove(e: any, { onStart, onMove, onEnd }: HandleEventOpts = {}) {

  if (!e.touches)
    e.preventDefault()

  const startPos = getPos(e)
  let lastPos = startPos

  const move = (e: any) => {
    const pos = getPos(e)
    if (onMove)
      onMove({ pos, deltaPos: { x: pos.x - lastPos.x, y: pos.y - lastPos.y}, startPos })
    lastPos = pos
  }

  const end = (e: any) => {
    const pos = getPos(e)
    document.removeEventListener("touchmove", move)
    document.removeEventListener("mousemove", move)
    if (onEnd)
      onEnd({ pos, deltaPos: { x: pos.x - lastPos.x, y: pos.y - lastPos.y}, startPos })
  }

  move(e)
  if (onStart)
    onStart({ pos: startPos, deltaPos: { x: 0, y: 0 }, startPos })

  if (e.touches) {
    document.addEventListener("touchmove", move)
    document.addEventListener("touchend", end, { once: true })
  } else {
    document.addEventListener("mousemove", move)
    document.addEventListener("mouseup", end, { once: true })
  }
}