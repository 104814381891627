import srcPreview from './preview.jpg'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import model from './butterflie.glb'

const butterfliePattern = {
  srcPreview,
  name: "Бабочки",
  pattern
}

export default butterfliePattern

async function pattern({}: PatternParams): Promise<Pattern> {

  const scene3d = new THREE.Scene()
  const loader = new GLTFLoader()
  const gltf = await loader.loadAsync(model)
  
  console.log(gltf)

  const mixer = new THREE.AnimationMixer( gltf.scene );
  for (let animation of gltf.animations)
    mixer.clipAction(animation).play()

  // Вытаскиваем 2D плоскость на другую сцену
 


  scene3d.add(gltf.scene)
  scene3d.scale.set(0.5, 0.5, 0.5)
 
  
  return {
    scene3d,
  
    mixer
  }
}