import { action, computed, makeObservable, observable } from "mobx"
import { initCamera, waitCameraData } from "./libs/camera"
import GL from "./services/gl"
import ImageProcessor from "./services/image-processor"

declare global {

  type QrMediaSrc = {
    videoSrc?: string,
    imageSrc?: string,
    preview?: string,
    isConverted?: boolean,
    progress?: number
  }

  type QrData = {
    uuid: string,
    canEdit?: boolean,
    src?: QrMediaSrc,
    design?: {
      pattern: PatternKey,
      scale: number,
      rotate?: number
    }
  }
}

type InitProps = {
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  refVideoElement?: HTMLVideoElement | null
}

export const previewQrData: QrData = {
  uuid: "_preview",
  canEdit: false,
  src: {
    imageSrc: "/preview.png"
  },
  design: {
    pattern: "",
    scale: 2
  }
} 

class ScannerStore {

  rendered = false

  data = ""
  dataTimeout = -1
  qrData: QrData | null = null

  imageProcessor = new ImageProcessor()
  gl!: GL
  stream!: MediaStream

  constructor(){
    makeObservable(this, {
      rendered: observable,
      data: observable,
      qrData: observable,
      uuid: computed,
      setData: action,
      setQrData: action
    })
  }
  
  async init({ video, canvas, refVideoElement }: InitProps){
    this.stream = await initCamera(video)
    await waitCameraData(video)
    await this.imageProcessor.init(video)

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    this.gl = new GL(canvas)
    this.gl.setVideoBackground(this.imageProcessor.canvas)
    if (refVideoElement) this.gl.refVideoElement = refVideoElement
    
    this.imageProcessor.process((data) => {
      this.gl.updateBackground()
      this._setData(data.data)
      //setFps(data.fps)
      //gl.render(data.matrix)
      this.gl.render2D(data.homography)
    })
  }

  _setData(data: string){
    
    if(!data){
      if(this.data && this.dataTimeout < 0)
        this.dataTimeout = setTimeout(() => this.setData(""), 100) as any
      return
    }

    this.setData(data)
  }

  setData(data: string){
    if(this.dataTimeout >= 0){
      clearTimeout(this.dataTimeout)
      this.dataTimeout = -1
    }
    this.data = data
  }

  get uuid(): string {
    if(!this.data) return ""
    const url = new URL(this.data)
    if (!url.pathname || url.pathname.length <= 1) return ""
    return url.pathname.slice(1)
  }

  setQrData(data: QrData | null){
    this.qrData = data
  }

  dispose(){
    this.imageProcessor.stop()
    if (this.stream)
      this.stream.getTracks().forEach(track => track.stop())
  }

}

export default ScannerStore