import srcPreview from './preview.jpg'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import model from './elka.glb'

const elkaPattern = {
  srcPreview,
  name: "Ёлка",
  hidden: true,
  pattern
}

export default elkaPattern

async function pattern({}: PatternParams): Promise<Pattern> {

  const scene3d = new THREE.Scene()
  const loader = new GLTFLoader()
  const gltf = await loader.loadAsync(model)
  
  console.log(gltf)

  const mixer = new THREE.AnimationMixer( gltf.scene );
  for (let animation of gltf.animations)
    mixer.clipAction(animation).play()

  // Вытаскиваем 2D плоскость на другую сцену
  const scene2d = new THREE.Scene()
  for(let child of gltf.scene.children) {
    if (child.name !== "2d-pattern") continue
    const mesh2d = child.removeFromParent()
    mesh2d.rotateX(-90 * Math.PI / 180)
    mesh2d.position.add(new THREE.Vector3(0, 0, -1))
    
    scene2d.add(mesh2d)
  }



  scene3d.add(gltf.scene)
  scene3d.scale.set(0.5, 0.5, 0.5)
  scene2d.scale.set(0.5, 0.5, 0.5)
  //scene2d.position.set(-5, 0, -1)
  
  return {
    scene3d,
    scene2d,
    mixer
  }
}