import { FunctionComponent } from "react";

const Footer: FunctionComponent = () => {

  return (
    <>
      <div>Если возникли проблемы, напишите нам на почту 
        <a href="mailto:info@letary.ru"> info@letary.ru</a> или TG: <a href="https://t.me/letary_bot">@letary_bot</a></div>
        <img src="/lettary-logo.png" alt="Лого lettar" style={{ margin: "1em", width: "120px" }}/>
      <div>2019-2022 © letary. Все права защищены</div>
    </>
  )
}

export default Footer