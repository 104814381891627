import cn from 'classnames';
import { useMainStore } from 'main-store';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ScannerStore, { previewQrData } from './store';
import styles from './style.module.sass'
import patterns from './ar-patterns'
import BottomPanel from './bottom-panel';
import codeImg from './code.png'
import Tools from './tools'

type ScannerProps = {
  preview?: boolean
}

const Scanner: FunctionComponent<ScannerProps> = ({ preview }) => {

  const mainStore = useMainStore()
  const [ playing, setPlaying ] = useState(false)
  const [ scannerStore ] = useState(() => new ScannerStore())

  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const srcVideoRef = useRef<HTMLVideoElement>(null)
  const srcPatternVideoRef = useRef<HTMLVideoElement>(null)

  // init scannerStore
  useEffect(() => {
    if(videoRef.current === null || canvasRef.current === null || srcVideoRef.current == null) return

    scannerStore.init({ 
      video: videoRef.current, 
      canvas: canvasRef.current,
      refVideoElement: srcVideoRef.current
    })

    return () => scannerStore.dispose()
  }, [ scannerStore ])

  const uuid = scannerStore.uuid
  useEffect(() => {

    const update = async () => {
      scannerStore.setQrData(null)
      if (!uuid) return

      const resp: QrData = await mainStore.api.getQrData(uuid)
      if ((resp as any).error) return
      
      if (!resp.src) 
        return scannerStore.setQrData(previewQrData)

      scannerStore.setQrData(resp)
    }
    update()
  }, [ uuid, scannerStore, mainStore ])

  const qrData = scannerStore.qrData
  useEffect(() => {

    if (qrData === null) {
      srcVideoRef.current?.pause()
      srcPatternVideoRef.current?.pause()
      if (scannerStore.gl)
        scannerStore.gl.showMedia = false
      return
    }

    if (qrData.src)
      scannerStore.gl.importMedia(qrData.src)

    if (qrData.src?.videoSrc && srcVideoRef.current){
      scannerStore.gl.setVideoPlayButton(!mainStore.isClicked)
      if (mainStore.isClicked) {
        srcVideoRef.current.play()
        .then(() => setPlaying(true))
        .catch(() => setPlaying(false))
        .then(() => mainStore.messageStore.alert("playing"))
        .catch((e: DOMException) => mainStore.messageStore.alert(JSON.stringify(e.message)))
      } else {
      }
    }

  }, [ qrData, scannerStore, mainStore ])

  const onPlayButtonClick = () => {
    if (qrData?.src?.videoSrc && srcVideoRef.current){
      srcVideoRef.current.muted = false
      if (srcPatternVideoRef.current && srcPatternVideoRef.current.src)
        srcPatternVideoRef.current.play()
      srcVideoRef.current.play()
      .then(() => mainStore.messageStore.alert("playing"))
      .catch((e: DOMException) => mainStore.messageStore.alert(JSON.stringify(e.message)))
      // srcVideoRef.current.muted = false
    }
    mainStore.makeClick()
    scannerStore.gl.setVideoPlayButton(false)
  }
  
  const gl = scannerStore.gl
  const mainStoreScale = (mainStore.currentCode?.uuid === qrData?.uuid) && mainStore.currentCode?.design?.scale
  const mainStoreRotate = (mainStore.currentCode?.uuid === qrData?.uuid) && mainStore.currentCode?.design?.rotate
  const scale = mainStoreScale || qrData?.design?.scale || 1.5
  const rotate = mainStoreRotate || qrData?.design?.rotate || 0

  useEffect(() => {
    if (!gl || !scale || !qrData) return
    gl.setGlobalScale(scale)
    gl.setMediaRotation(rotate)
  }, [ gl, scale, rotate, qrData ])

  const mainStorePattern = (mainStore.currentCode?.uuid === qrData?.uuid) && mainStore.currentCode?.design?.pattern
  const selectedPattern = typeof(mainStorePattern) === "string"? mainStorePattern: qrData?.design?.pattern
  useEffect(() => {
    if (!gl) return
    gl.removePatternScene()
    if (!selectedPattern) return
    patterns[selectedPattern].pattern({ 
      videoElement: srcPatternVideoRef.current
    }).then(patternScene => {
      if (!patternScene.hasVideo && srcPatternVideoRef.current)
        srcPatternVideoRef.current.src = ""
      gl.setPatternScene(patternScene)
    })
  }, [ gl, selectedPattern, mainStorePattern ])

  const onButtonClick = () => {
    if(!scannerStore.uuid) return
    mainStore.initCode(scannerStore.uuid)
    mainStore.openPage("createProject")
  }

  return (
    <div className={styles.container}>
      { qrData && qrData.src && qrData.src.isConverted === false && (
        <div className={styles.topPanel}>
          <div>Идёт обработка видео</div>
          <div>{Math.floor(qrData.src.progress || 0)}%</div>
        </div>
      )}
      <div className={cn({ [styles.overlay]: true, [styles.hide]: scannerStore.uuid })}>
        <div>Наведите на QR-код</div>
        <img src={codeImg} alt="QR-код"/>
      </div>
      <video ref={videoRef} muted={true} playsInline={true} autoPlay={true} className={styles.cameraVideo}/>
      <canvas onClick={onPlayButtonClick} ref={canvasRef} className={styles.mainCanvas}/>
      <video ref={srcVideoRef} muted={false} autoPlay={false} style={{ display: "none "}} playsInline={true} loop={true}/>
      <video ref={srcPatternVideoRef} muted={true} style={{ display: "none "}} playsInline={true} loop={true}/>
      <Tools scannerStore={scannerStore} />)
      <button className={cn("btn", styles.uploadBtn, !(qrData && qrData.uuid === "_preview") && styles.hide)} onClick={onButtonClick}>
        Создать свою AR
      </button>
      {qrData && qrData.canEdit && (
        (mainStore.currentCode && mainStore.currentCode.uuid === qrData.uuid)? (
          <BottomPanel/>
        ): (!qrData.design? (
          <button className={cn("btn", styles.uploadBtn)} onClick={() => mainStore.initCode(qrData.uuid)}>Выбрать шаблон</button>
        ): null)
      )}
    </div>
  );
}

export default observer(Scanner) as FunctionComponent<ScannerProps>;
