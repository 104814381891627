import srcPreview from './preview.jpg'
import * as THREE from 'three'
import { getTransparentShader } from 'libs/green-screen-shader'
import { waitCameraData } from 'scanner/libs/camera'
import videoClip from './04.mp4'
//import oneone from './oneone.jpg'



const elka2Pattern = {
  srcPreview,
  name: "Елка2",
  hidden: true,
  pattern
}

export default elka2Pattern


const matchingColor = new THREE.Color(0x00d900)
async function pattern({ videoElement}: PatternParams): Promise<Pattern> {

  if (!videoElement) return {}

  const scene2d = new THREE.Scene()

  const videoTexture = new THREE.VideoTexture( videoElement );
  if (videoElement){
    videoElement.src = videoClip
    videoElement.play()
  }
  
  await waitCameraData(videoElement, 3)

  const material = new THREE.ShaderMaterial(getTransparentShader(matchingColor, videoTexture))

  const geometry = new THREE.PlaneGeometry(0.7, 1.4)
  const plane1 = new THREE.Mesh( geometry, material );

 




  scene2d.add(plane1);

  


  

  //console.log((gltf.scene.children[0] as THREE.Mesh).geometry.getIndex())
  return {
    scene2d,
    hasVideo: true
  }

}