export default function setVhOnDocument () {

  function resize(){
    // We execute the same script as before
    let vh = window.innerHeight * 0.01 - 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  window.addEventListener('resize', resize);
  resize();
  
}

