import { useMainStore } from 'main-store';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '../style.module.sass'
import patterns from '../ar-patterns'

type BottomPanelProps = {
  
}

const BottomPanel: FunctionComponent<BottomPanelProps> = observer(() => {

  const mainStore = useMainStore()
  const selected = mainStore.currentCode?.design?.pattern || ""
  const onChange = (pattern: PatternKey) => mainStore.setCurrentCodePattern(pattern)

  const onBackClick = () => {
    const params = new URLSearchParams(window.location.search)
    const editUuid = params.get("edit")
    if (editUuid)
      mainStore.openPage("edit")
    else
      mainStore.openPage("createProject")
  }

  const keys = Object.keys(patterns).filter(key => !patterns[key].hidden)

  return (
    <div className={styles.bottomPanel}>
      <div className={styles.patternTitle}>{patterns[selected].name}</div>
      <Swiper 
        style={{ width: "100%", height: "4.8em" }}
        threshold={1}
        slidesPerView={5}
        centeredSlides={true}
        edgeSwipeDetection={true}
        onSlideChange={(swiper) => onChange(keys[swiper.activeIndex] as PatternKey)}
        slideToClickedSlide={true}
        onSwiper={swiper => swiper.activeIndex = keys.indexOf(selected)}
      >
      { keys.map((key) => (
        <SwiperSlide key={key}>
          <div className={styles.pattern}>
            <div style={{ backgroundImage: `url(${patterns[key as PatternKey].srcPreview})`}}></div>
          </div>
        </SwiperSlide>
      ))}
      </Swiper>
      <div className={styles.bottomButtons}>
        <button onClick={onBackClick}>Отмена</button>
        <button onClick={() => mainStore.openPage("final")}>Создать проект</button>
      </div>
    </div>

  )
})

export default BottomPanel