import { useMainStore } from "main-store";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import SliderScaler from "scanner/tools/slider-scaler";
import ScannerStore from "scanner/store";
import RotateTool from "./rotate";

type ToolsProps = {
  scannerStore: ScannerStore
}

const Tools: FunctionComponent<ToolsProps> = ({ scannerStore }) => {

  const mainStore = useMainStore()
  const qrData = scannerStore.qrData

  if(!qrData || mainStore.currentCode?.uuid !== qrData?.uuid)
    return null

  return (
    <>
      <SliderScaler/>
      <RotateTool/>
    </>
  )
}

export default observer(Tools) 