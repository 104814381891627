import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import "./styles/global.sass"
import 'swiper/css'

//Import all providers
import { MainStoreProvider } from './main-store'

import App from "pages/app"
import setVhOnDocument from 'libs/set-vh';
import MessageLayout from 'components/message-layout';

ReactDOM.render(
  <React.StrictMode>
    <MainStoreProvider>
      <MessageLayout>
        <App title="Lettar"/>
      </MessageLayout>
    </MainStoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
setVhOnDocument()
