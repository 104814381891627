import styles from './styles.module.sass'
import icon from './edit-link-icon.svg'
import { FunctionComponent } from 'react'
import { useMainStore } from 'main-store'

const EditLink: FunctionComponent<{ title: string }> =  ({ title }) => {

  const mainStore = useMainStore()

  const onCopy = () => {
    navigator.clipboard.writeText(title)
    mainStore.messageStore.alert("Ссылка скопирована")
  }

  return (
    <div className={styles.input}>
      {title}
      <button onClick={onCopy}>
        <img src={icon} alt="icon"/>
      </button>
    </div>
  )

}

export default EditLink