import { AnimationMixer, Scene } from "three"


import BlankPattern from './blank-pattern'
import zhetonPattern from './23feb-zheton'
import dadPattern from './23feb-dad'
import rusPattern from './23feb-rus'
import airplanePattern from './23feb-airplane'
import roseframePattern from './rose-frame'



import NaturePattern from './nature-pattern'
import RosePattern from './rose-pattern'
import elkaPattern from './elka-pattern'
import podarkiPattern from './podarki-pattern'
import newyear1Pattern from './newyear1'
import newyear2Pattern from './newyear2'
import elka2Pattern from './elka2-pattern'
import heartPattern from './heart-pattern'
import hippiepartyPattern from './hippieparty-pattern'
import butterfliePattern from './butterflie-pattern'
import feb14wreathPattern from './feb14wreath-pattern'
import cloudPattern from './cloud-pattern'


declare global {
  type PatternKey = string
  type PatternParams = {
    videoElement: HTMLVideoElement | null
  }
}

type PatternInfo = {
  srcPreview: string,
  name: string,
  pattern: (params: PatternParams) => Promise<Pattern>,
  hidden?: boolean
}

const patterns: { [ key: string ]: PatternInfo } = {
  "": BlankPattern,
 
  "roseframe": roseframePattern,
  
  "nature": NaturePattern,
  "rose": RosePattern,
  "elka": elkaPattern,
  "podarki": podarkiPattern,
  "newyear1": newyear1Pattern,
  "newyear2": newyear2Pattern,
  "elka2": elka2Pattern,
  "heart": heartPattern,
  
  "hippieParty": hippiepartyPattern,
  "butterflie": butterfliePattern,
  "feb14wreath": feb14wreathPattern,
  "cloud": cloudPattern,
  
  "zheton": zhetonPattern,
  "dad": dadPattern,
  "rus": rusPattern,
  "airplane": airplanePattern,
  
  

}

export default patterns

declare global {
  type Pattern = {
    scene2d?: Scene,
    scene3d?: Scene,
    hasVideo?: boolean,
    mixer?: AnimationMixer
  }
}