import { Color, VideoTexture } from "three"

const vertexTransparentShader = `
varying vec2 vUv;

void main() {
    vUv = uv;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const fragmentTransparentShader = `
uniform sampler2D _texture;
uniform vec3 color;
varying vec2 vUv;
void main(void)
{
  vec3 tColor = texture2D( _texture, vUv ).rgb;
  float a = (length(tColor - color) - 0.65) * 12.0;
  gl_FragColor = vec4(tColor, a);
}
`

export const getTransparentShader = (color: Color, videoTexture: VideoTexture) => ({
  transparent: true,
  uniforms: {
    color: {
      type: 'c',
      value: { x: color.r, y: color.g, z: color.b }
    },
    _texture: {
      type: 't',
      value: videoTexture
    }
  },
  vertexShader: vertexTransparentShader,
  fragmentShader: fragmentTransparentShader
})