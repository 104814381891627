import cn from 'classnames';
import { getMove } from 'libs/touches';
import { useMainStore } from 'main-store';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useRef, useState } from 'react'
import styles from './style.module.sass'

type SliderScalerlProps = {
  
}
const minScale = 1
const maxScale = 2.6

const SliderScaler: FunctionComponent<SliderScalerlProps> = observer(() => {

  const mainStore = useMainStore()
  const normScale = 100-((mainStore.currentCode?.design?.scale || 1.5) - minScale) / (maxScale-minScale) * 100

  const [ active, setActive ] = useState(false)
  const [ sliderPos, setSliderPos ] = useState(normScale)
  const containerRef = useRef<HTMLDivElement>(null)

  const onMouseDown = (e: any) => {

    if (!containerRef.current) return
    const rect = containerRef.current.getBoundingClientRect()
    
    getMove(e, {
      onStart: () => setActive(true),
      onMove: ({ pos, startPos }) => {
        let scl = (pos.y - rect.top)/rect.height * 100
        if (scl < 0) scl = 0
        if (scl > 100) scl = 100
        setSliderPos(scl)
        mainStore.setScale(minScale + (1 - scl/100)*(maxScale-minScale))
      },
      onEnd: () => {
        setActive(false)
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.lineWrapper} ref={containerRef} onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
        <div className={styles.line}>
        </div>
        <div className={cn(styles.thumb)} style={{ top: sliderPos+"%" }}></div>
      </div>
      <div className={cn(styles.thumbBorder, active && styles.active)} style={{ top: sliderPos+"%" }}></div>
    </div>
  )

})

export default SliderScaler