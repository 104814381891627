import { initCanvas } from "../libs/camera"
import { hypot, multiply } from "mathjs"
import QR from "./qr"
// @ts-ignore
import jsfeat from 'jsfeat'
import { bufferToArray, bufferToPoints, decompose, getCameraMatrix, distance } from "../process-image/decompose"

const point_status = new Uint8Array(4)
const homo_kernel = new jsfeat.motion_model.homography2d();

const homo_transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t)
const affine_transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t)

const one = [
	[ 1, 0, 0 ],
	[ 0, 1, 0 ],
	[ 0, 0, 1 ]
]

type Point = {
	x: number,
	y: number
}

type ImageProcessorCallbackProps = {
	fps: number, 
	imageData: ImageData, 
	matrix: number[][] | null, 
	homography: number[][] | null, 
	points: Point[] | null,
	data: string
}

class ImageProcessor {

	delay = 0
	lastTimeQuery = 0
	lastTime = 0
	qr = new QR()
	width = 0
	height = 0
	stopFlag = false

	video!: HTMLVideoElement
	getImageData!: (video: HTMLVideoElement) => ImageData
	canvas!: HTMLCanvasElement
	initialPoints!: Point[]
	cameraMatrix!: number[][]
	homography: number[][] | null = null
	data: string = ""

	options = {
		win_size: 100,
		max_iterations: 120,
		epsilon: 0.01,
		min_eigen: 0.005,
		levels: 8
	}

	curr_img_pyr = new jsfeat.pyramid_t(this.options.levels)
	prev_img_pyr = new jsfeat.pyramid_t(this.options.levels)

	prev_xy = new Float32Array(4*2);
	curr_xy = new Float32Array(4*2);

	async init(video: HTMLVideoElement){
		
		await this.qr.init()
		this.width = video.videoWidth
		this.height = video.videoHeight
		const { getImageData, canvas } = initCanvas(this.width, this.height )
		
		this.video = video
		this.getImageData = getImageData
		this.canvas = canvas

		this.curr_img_pyr.allocate(this.width, this.height, jsfeat.U8_t|jsfeat.C1_t)
		this.prev_img_pyr.allocate(this.width, this.height, jsfeat.U8_t|jsfeat.C1_t)

		const scale = 0.5
		this.initialPoints = [
			{ x: -scale, y: scale },
			{ x: scale, y: scale },
			{ x: scale, y: -scale },
			{ x: -scale, y: -scale }
		]

		this.cameraMatrix = getCameraMatrix(this.height, this.width)

		this.lastTime = Date.now()
	}

	canFindQR(){
		return this.lastTimeQuery >= 0 && Date.now() > this.lastTimeQuery+this.delay
	}

	findQR(imageData: ImageData){
		this.lastTimeQuery = -1
		this.qr.findQR(imageData).then((code) => {

			this.lastTimeQuery = Date.now()
			if(code === null) return

			const { 
				bottomLeftFinderPattern, bottomRightAlignmentPattern, topRightFinderPattern, topLeftFinderPattern,
				bottomLeftCorner, bottomRightCorner, topRightCorner, topLeftCorner
			} = code.location
			if(!bottomRightAlignmentPattern) return

			const corners = [ bottomLeftCorner, bottomRightCorner, topRightCorner, topLeftCorner ]
			
			const points = [
				bottomLeftFinderPattern, bottomRightAlignmentPattern, topRightFinderPattern, topLeftFinderPattern
			]
			
			const h1 = hypot(corners[0].x - corners[1].x, corners[0].y - corners[1].y)
			const h2 = hypot(corners[0].x - corners[3].x, corners[0].y - corners[3].y)

			this.options.win_size = Math.floor(Math.max(h1, h2) / 5)
			//console.log(this.options.win_size)

			homo_kernel.run(this.initialPoints, corners, homo_transform, 4)

			this.homography = bufferToArray(homo_transform.data, 3, 3)
			
			for(let i = 0; i < 4; i++){
				this.curr_xy[i*2] = points[i].x
				this.curr_xy[i*2+1] = points[i].y
			}
			
			this.delay = 5000
			this.saveFrame(imageData)
			this.swap()

			this.data = code.data
		})
	}

	saveFrame(imageData: ImageData){
		jsfeat.imgproc.grayscale(imageData.data, this.width, this.height, this.curr_img_pyr.data[0])
		this.curr_img_pyr.build(this.curr_img_pyr.data[0], true)
	}

	swap(){
		const xy = this.curr_xy
		this.curr_xy = this.prev_xy
		this.prev_xy = xy

		const img_pyr = this.curr_img_pyr
		this.curr_img_pyr = this.prev_img_pyr
		this.prev_img_pyr = img_pyr

	}

	computeOpticalFlow(imageData: ImageData){
		if (this.homography === null) return
		this.saveFrame(imageData)
		
		jsfeat.optical_flow_lk.track(
			this.prev_img_pyr, this.curr_img_pyr, 
			this.prev_xy, this.curr_xy, 
			4, 
			this.options.win_size, 
			this.options.max_iterations, 
			point_status, 
			this.options.epsilon, 
			this.options.min_eigen
		)

		for(let i = 0; i < 4; i++)
			if(point_status[i] === 0){
				this.homography = null
				this.delay = 0
				return null
			}
		
		homo_kernel.run(bufferToPoints(this.prev_xy), bufferToPoints(this.curr_xy), affine_transform, 4)

		const T = bufferToArray(affine_transform.data, 3, 3)
		
		const delta = distance (T, one)

		this.lastTime = Date.now()

		if(delta < 0.4){
			this.homography = multiply(T, this.homography)
			this.swap()
		}else{
			this.delay = 0
		}
		
		if(delta > 1){
			this.homography = null
			return null
		}

		const matrix = decompose(this.homography, this.cameraMatrix)
		return matrix
	}

	process(callback: (props: ImageProcessorCallbackProps) => void) {
		
		const computeImage = () => {

			const imageData = this.getImageData(this.video)

			if(this.canFindQR())
				this.findQR(imageData)

			const fps = 1/(Date.now() - this.lastTime)*1000

			if(this.homography){
				const matrix = this.computeOpticalFlow(imageData)
				if(matrix) callback({ 
					fps, 
					imageData, 
					matrix, 
					homography: this.homography, 
					points: bufferToPoints(this.prev_xy),
					data: this.data
				})
			}else{
				this.lastTime = Date.now()
				callback({ fps, imageData, homography: null, matrix: null, points: null, data: "" })
			}
			
			if(!this.stopFlag)
				requestAnimationFrame(computeImage)
		}
		computeImage()
	}

	stop(){
		this.stopFlag = true
	}


}

export default ImageProcessor