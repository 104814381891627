import { makeAutoObservable } from "mobx"
import API from "./api"
import FileStore from "./file-store"
import MessageStore from "./message-store"
import { nanoid } from 'nanoid'

function initDeviceId() {
  const _deviceId = localStorage.getItem('deviceId')
  if (_deviceId) return _deviceId
  const deviceId = nanoid()
  localStorage.setItem('deviceId', deviceId)
  return deviceId
}

class MainStore {
  
  page: "pre" | "" | "createProject" | "preview" | "edit" | "final" = ""

  currentCode: QrData | null = null
  isClicked = false

  messageStore: MessageStore
  fileStore: FileStore
  api: API
  deviceId: string | null

  constructor(){
    makeAutoObservable(this)
    this.messageStore = new MessageStore()
    this.fileStore = new FileStore(this)
    this.api = new API(this)
    this.deviceId = initDeviceId()
  }

  init() {
    const params = new URLSearchParams(window.location.search)
    if (params.has("edit"))
      this.openPage("edit")
  }

  makeClick() {
    this.isClicked = true
  }

  openPage(page: (typeof this.page)) {
    this.page = page
  }
  
  clearAll(){
    this.currentCode = null
    this.page = ""
  }

  initCode(uuid: string) {
    this.currentCode = { 
      uuid, 
      design: { pattern: "", scale: 1.5 }
    }
  }

  setCurrentCodePattern(pattern: PatternKey){
    if (!this.currentCode?.design) return
    this.currentCode.design.pattern = pattern
  }
  
  setScale(scale: number) {
    if (!this.currentCode?.design) return
    this.currentCode.design.scale = scale
  }

  setRotate(rotate: number) {
    if (!this.currentCode?.design) return
    this.currentCode.design.rotate = rotate
  }

  async uploadFile () {

  }
}

export default MainStore