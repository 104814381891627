import { ChangeEvent, createRef, FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { useMainStore } from 'main-store'
import Footer from 'components/footer'

type CreateProjectPageProps = {
  uuid: string
}

function CreateProjectPage ({ uuid }: CreateProjectPageProps) {

  const mainStore = useMainStore()
  const fileRef = createRef<HTMLInputElement>()

  const upload = (type: "image" | "video") => {
    if (fileRef.current === null) return
    if (type === "image")
      fileRef.current.accept = "image/*"
    if (type === "video")
      fileRef.current.accept = "video/*"
    fileRef.current.click()
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0)
    if (!file) return

    mainStore.fileStore.uploadFile(file)
  }

  return (
    <div className="page">
      <div className="page-content">
        <h1>Создать проект</h1>
        <div>Рекомендуем загружать фото и видео в вертикальном формате</div>
        {(mainStore.fileStore.status === '') && (
          <div style={{ marginTop: "0.5em" }}>
            <button className="btn" style={{ margin: "0.5em 0.2em" }} onClick={() => upload("image")}>
              Загрузить фото
            </button>
            <button className="btn" style={{ margin: "0.5em 0.2em" }} onClick={() => upload("video")}>
              Загрузить видео
            </button>
            <div style={{ fontSize: "0.8em", marginTop: "1em" }}>
              {"Нажимая на кнопку загрузки, вы соглашаетесь с нашей "}
              <a href="http://letary.ru/pravicy/" target="_blank">политикой конфиденциальности</a>
            </div>
          </div>
        )}
        {(mainStore.fileStore.status === 'loading') && (
          <div style={{ marginTop: "0.5em" }}>
            <div>Идет загрузка файла...</div>
            <div style={{ fontSize: "0.95em", marginTop: "0.4em" }}>
              {mainStore.fileStore.loadedProgress}
            </div>
          </div>
        )}
        {(mainStore.fileStore.status === 'compressing') && (
          <div style={{ marginTop: "0.5em" }}>
            <div>Видео обрабатывается...</div>
            <div style={{ fontSize: "0.95em", marginTop: "0.4em" }}>
              {mainStore.fileStore.loadedProgress}
            </div>
          </div>
        )}
        {(mainStore.fileStore.status === 'error') && (
          <div style={{ marginTop: "0.5em" }}>
            <div>При загрузке файла произошла ошибка</div>
          </div>
        )}
      </div>
      
      <Footer/>
      <input type="file" ref={fileRef} style={{display: "none"}} onChange={onFileChange}/>
    </div>
  )
}


export default observer(CreateProjectPage) as FunctionComponent<CreateProjectPageProps>;
