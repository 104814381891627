import { FunctionComponent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMainStore } from 'main-store'
import EditLink from 'components/edit-link'
import Footer from 'components/footer'

type FinalPageProps = {

}

function FinalPage () {

  const mainStore = useMainStore()
  const [ editLink, setEditLink ] = useState("")
 
  useEffect(() => {
    if (!mainStore.currentCode) return 
    const uuid = mainStore.currentCode.uuid
    mainStore.api.sendDesignData(uuid, mainStore.currentCode.design).then(resp => {
      console.log(resp)
      if (resp.error) return
      setEditLink(`${window.location.host}/${uuid}?edit=${resp.edit_uuid}`)
    })
  }, [ mainStore ])

  return (
    <div className="page">
      <div className="page-content">
        <h1>Сохранение проекта</h1>
        
        <div>Проект сохранен!</div>
        <div style={{margin: "1em"}}>Чтобы иметь возможность изменить проект, скопируйте ссылку:</div>
        
        <EditLink title={editLink}/>
        <button style={{margin: "1em"}} className="btn" onClick={() => mainStore.clearAll()}>Вернуться на сайт</button>
        
      </div>
      <Footer/>
    </div>
  )
}

export default observer(FinalPage) as FunctionComponent<FinalPageProps>;
