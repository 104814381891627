import srcPreview from './preview.jpg'

const BlankPattern = {
  srcPreview,
  name: "Пустой шаблон",
  pattern
}

export default BlankPattern

async function pattern({}: PatternParams): Promise<Pattern> {
  return {}
}