import srcPreview from './preview.jpg'
import * as THREE from 'three'
import { getTransparentShader } from 'libs/green-screen-shader'
import { waitCameraData } from 'scanner/libs/camera'
import videoClip from './03.mp4'
//import oneone from './oneone.jpg'



const new_year2Pattern = {
  srcPreview,
  name: "Новый год2",
  hidden: true,
  pattern
}

export default new_year2Pattern


const matchingColor = new THREE.Color(0x00d900)
async function pattern({ videoElement}: PatternParams): Promise<Pattern> {

  if (!videoElement) return {}

  const scene2d = new THREE.Scene()

  const videoTexture = new THREE.VideoTexture( videoElement );
  if (videoElement){
    videoElement.src = videoClip
    videoElement.play()
  }
  
  await waitCameraData(videoElement, 3)

  const material = new THREE.ShaderMaterial(getTransparentShader(matchingColor, videoTexture))

  const geometry = new THREE.PlaneGeometry(3, 2)
  const plane1 = new THREE.Mesh( geometry, material );

  //var img = new THREE.MeshLambertMaterial({
    ////map:THREE.ImageUtils.loadTexture(oneone), transparent:true,
  //});
  
  //var plane2 = new THREE.Mesh(new THREE.PlaneGeometry(2,1),img);
  //plane2.position.add(new THREE.Vector3(0, 0, 0.1))




  scene2d.add(plane1);
  //scene2d.add(plane2)
  


  

  //console.log((gltf.scene.children[0] as THREE.Mesh).geometry.getIndex())
  return {
    scene2d,
    hasVideo: true
  }

}